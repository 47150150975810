* {
  font-family: LugfaRegular;
  font-feature-settings: 'salt' 2, 'ss02' 1 !important;
}

.content {
  display: flex;
  flex-direction: column;
  background: #f3f3f3;

  justify-content: space-between;
  gap: 150px;
}

.boxes {
  justify-self: center;
  justify-content: center;
  align-items: center;

  width: 80%;
  height: fit-content;
}

@font-face {
  font-family: LugfaRegular;
  src: url(./lufga/LufgaRegular.ttf);
  font-feature-settings: 'salt' 2, 'ss02' 1;
}

h1 {
  font-family: LugfaRegular;
}

h2 {
  font-family: LugfaRegular;
}

h3 {
  font-family: LugfaRegular;
}

h4 {
  font-family: LugfaRegular;
}

p {
  font-family: LugfaRegular;
}

.MuiButton-contained {
  font-family: LugfaRegular;
}

pre {
  height: 500px;
  overflow-x: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
}