.scrollbar {
    height: 500px;
    overflow-x: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: justify;
    font-size: .8em;
    color: #6B6B6B;
    padding: 10px;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #F5F5F5;
    border-radius: 25px;
}

::-webkit-scrollbar-thumb {
    background-color: #F2800A;
    border-radius: 25px;
}