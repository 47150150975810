.items {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 80%;

  top: 5%;

  font-family: LugfaRegular;
}

.items > img {
    width: 35%;
    margin-bottom: 15px;
}

.terms {
  align-items: center;
  justify-content: center;
  width: 80%;
}

.steps {
  text-align: center;
  width: 60%;
  font-size: 36px;
  font-weight: 900;
  color: #121217;
}

.desc {
  text-align: center;
  width: 50%;
  font-weight: 400;
  color: #6C6C89;
}

.desc2 {
  text-align: center;
  width: 50%;
  font-weight: 700;
  color: #6C6C89;
}

.names {
  text-align: left;
  font-size: 20px;
  color: #020D0F;
  padding: 0;
  margin-bottom: 5px;
}

.buttons {
  color:  #002129;
}

button {
  border-radius: 8px !important;
  font-weight: 700 !important;
}

.MuiInputBase-formControl {
  border-radius: 8px !important;
}

.grid {
  width: 60%;
}

h2.agreement {
  font-size: .9rem;
  color: #132F41;
}

@media all and (max-width: 800px) {
  .items {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 90%;
    top: 5%;
    font-family: LugfaRegular;
  }

  .steps {
    text-align: center;
    width: 80%;
    font-size: 1.5em;
    font-weight: 900;
  }

  .desc {
    text-align: center;
    width: 80%;
    font-weight: 10;
    font-size: 1.1em;
  }

  .names {
    text-align: left;
    font-size: 1.1em;
    padding: 0;
    margin-bottom: 5px;
  }
  
  .grid {
    width: 85%;
  }

  .items > img {
    width: 75%;
  }
}